var shouldRunOnContentReplaceEvent = true;

let CmsModalUtils = {
    hiddingClass: 'sr-only-modal',
    modalParts: '.modal_popup, .modal_popup_underlay',
    activeModalRef: null,
    activeTargetNode: null,
    hideOnDemand: function() {
        $(this.modalParts).addClass(this.hiddingClass);
    },
    showOnDemand: function() {
        $(this.modalParts).removeClass(this.hiddingClass);
    },
    resetReferences: function() {
        this.activeModalRef = null;
        this.activeTargetNode = null;
    },
    destroyModal: function() {
        if (this.activeModalRef !== null) {
            this.activeModalRef.hide();
            this.activeModalRef.find("> .head .hideButton").trigger('click');
            this.resetReferences();
            $(document).find('.modal_popup_underlay').hide();
            $(document).offPage(rwd.ScreenSize.RESIZE_EVENT_NAME+".modal");
        }
    },
    blockBodyScroll: function() {
        if (rwd.ScreenSize.is(rwd.ScreenSize.SIZES.XSMALL) || rwd.ScreenSize.is(rwd.ScreenSize.SIZES.SMALL)) {
            var scrollWidth = (window.innerWidth - document.documentElement.clientWidth);
            $('body')
                .addClass("isScrollBlockedModal")
                .css('padding-right', scrollWidth + 'px');
        }
    },
    removeBlockBodyScroll: function(pos) {
        var $body = $('body'),
            isScrollBlockedModal = $body.hasClass("isScrollBlockedModal");
        if (isScrollBlockedModal) {
            $body
                .removeClass("isScrollBlockedModal")
                .css('padding-right', '')
                .delay(0)
                .queue(function() {
                    var $self = $(this),
                        bodyStyle = $self.attr('style'),
                        bodyHasEmptyStyle = typeof bodyStyle === 'string' && bodyStyle === '';
                    if (bodyHasEmptyStyle) {
                        $self.removeAttr('style');
                    }
                    $self.dequeue();
            });

            if (pos) {
                $('body,html').animate({ scrollTop: pos }, 0);
            }
        }
    }
};

function runModalUtils() {
    var ScreenSize = rwd.ScreenSize;

    function isMobile() {
        return ScreenSize.is(ScreenSize.SIZES.XSMALL) || ScreenSize.is(ScreenSize.SIZES.SMALL);
    }

    function addParameters(url, params) {
        var prop;
        var qmIndex = url.indexOf("?");
        var begin = "?";
        if (qmIndex > -1) {
            begin = "&";
        }
        var pairs = [];
        for (prop in params) {
            if (!params.hasOwnProperty(prop)) {
                return;
            }
            pairs.push(prop + "=" + encodeURIComponent(params[prop]));
        }
        return url + begin + pairs.join("&");
    }

    function makeModal(title, modalExtraClass) {
        var node = $("<div>").addClass("modal_popup").html('<div class="head"><span class="hideButton"><span>X</span></span><div class="title"></div></div><div class="body"></div>');
        if (!!modalExtraClass) {
            node.addClass(modalExtraClass);
        }
        if (title) {
            node.addClass("titled").find(".head").find("> .title").html(title);
        }
        return node;
    }

    function isModalResizer(modal) {
        return modal.hasClass('iframe-resizer');
    }

    function setModalBodyHeight(modal, windowVh) {
        // 70px, space between window vertical edge for desktop via css
        let verticalSpacer = isMobile() ? '0px' : '70px';
        let headerHeight = modal.find('.head').outerHeight() + 'px';
        return !isModalResizer(modal) && modal.find('.body').css({
            'max-height': 'calc(('+windowVh+' * 100) - '+verticalSpacer+' - '+headerHeight+')'
        });
    }

    function getWindowHeight() {
        return (window.innerHeight * 0.01) + 'px';
    }

    function modalShowOptions(modal) {
        CmsModalUtils.blockBodyScroll();
        return {
            duration: 0,
            complete: function() {
                const timeoutDelay  = 75;
                let timeoutHeight = setTimeout(function() {
                    setModalBodyHeight(modal, getWindowHeight());
                    clearTimeout(timeoutHeight);
                }, timeoutDelay);
                $(document).onPage(ScreenSize.RESIZE_EVENT_NAME+".modal", function() {
                    setModalBodyHeight(modal, getWindowHeight());
                });
                return $(document).trigger('modal.show').offPage('modal.show');
            }
        }
    }

    $('#site-wrapper').onPage("click", 'A.modal', function(e) {
        e.preventDefault();
        e.stopPropagation();
        var $link = $(this),
            $linkHeight = $(this).height();

        const targetTopPos = $link.offset().top - $linkHeight;
        $link.prop('disabled', true);
        if (!$link.hasClass('disabled')) {
            var hideFunction,
                href = $link.attr('href'),
                hIndex = href.indexOf("#"),
                targetNode,
                title = $link.data("title") || "",
                modal,
                modalExtraClass = $link.data("class") || "",
                under = $(".modal_popup_underlay"),
                modalResizerClass  = 'iframe-resizer',
                modalResizerAttrID = 'modalResizer';
            if (!under[0]) {
                under = $("<div>").addClass("modal_popup_underlay").appendTo("body");
            }
            if (!!modalExtraClass) {
                under.addClass(modalExtraClass + '_underlay');
            }
            under.offPage("click");
            // trzeba poprawić warunek dla obcych urli z hashem
            if (hIndex === 0) {
                targetNode = $(href);
                if (targetNode[0]) {
                    modal = makeModal(title, modalExtraClass);
                    modal.hide();
                    targetNode.before(modal);
                    targetNode.addClass('js-modalContent');
                    hideFunction = function() {
                        let originalSrc = '';
                        modal.find('iframe').each(function() {
                            const iframe = this;
                            originalSrc = iframe.getAttribute('src') || '';
                            const srcWithoutAutoplay = originalSrc.replace('?autoplay=1', '');
                            iframe.setAttribute('src', srcWithoutAutoplay);
                        });
                        CmsModalUtils.removeBlockBodyScroll(targetTopPos);
                        modal.hide();
                        modal.after(targetNode.hide());
                        modal.remove();
                        under.hide();
                        $link.prop('disabled', false);
                        CmsModalUtils.resetReferences();
                        $(document).offPage(ScreenSize.RESIZE_EVENT_NAME+".modal");
                    };
                    modal.find("> .head .hideButton").onPage('click', hideFunction);
                    modal.find("> .body").append($("<div>").addClass("inner").append(targetNode.show()));
                    if ($link.hasClass(modalResizerClass)) {
                        modal.addClass(modalResizerClass);
                    }
                    modal.show(modalShowOptions(modal));
                    under.onPage('click', hideFunction);
                }
            } else {
                modal = makeModal(title, modalExtraClass);
                modal.hide();
                $("body").append(modal);
                hideFunction = function() {
                    CmsModalUtils.removeBlockBodyScroll(targetTopPos);
                    modal.hide().remove();
                    under.hide();
                    $link.prop('disabled', false);
                    CmsModalUtils.resetReferences();
                    $(document).offPage(ScreenSize.RESIZE_EVENT_NAME+".modal");
                };
                modal.find("> .head .hideButton").onPage('click', hideFunction);
                if ($link.hasClass(modalResizerClass)) {
                    modal.addClass(modalResizerClass);
                    modal.find(".body").html('<iframe id="' + modalResizerAttrID + '" frameborder="0" src="' + addParameters(href, {"mobile": isMobile()}) + '"></iframe>');
                    $('.' + modalResizerClass).find('.body').append('<div class="resizer-preloader"><span class="resizer-loaded"></span></div>');
                    (function() {
                        var interval = null;
                        interval = ComponentsManager.setPageInterval(function(){
                            var x = $('#' + modalResizerAttrID).attr('height');
                            if (x > 0 || x !== null) {
                                ComponentsManager.setPageTimeout(function() {
                                    $('#' + modalResizerAttrID).closest('.' + modalResizerClass).addClass('iframe-resizer-loaded');
                                    $('#' + modalResizerAttrID).css('max-height', $('#' + modalResizerAttrID).attr('height') + 'px');
                                    $('#' + modalResizerAttrID).animate({
                                        'maxHeight': $('#' + modalResizerAttrID).attr('height') + 'px'
                                    }, 0, function() {
                                        ComponentsManager.setPageTimeout(function() {
                                            $('#' + modalResizerAttrID).removeAttr('style');
                                        }, 500);
                                    });
                                }, 1000);
                                clearInterval(interval);
                                interval = null;
                            }
                        }, 1000);
                    }).call(this);
                } else {
                    modal.find(".body").html('<iframe frameborder="0" src="' + addParameters(href, {"mobile": isMobile()}) + '"></iframe>');
                }
                modal.show(modalShowOptions(modal));
                under.onPage('click', hideFunction);
            }
            if (modal.length > 0 ) {
                CmsModalUtils.destroyModal();
            }
            CmsModalUtils.activeModalRef = modal;
            CmsModalUtils.activeTargetNode = targetNode;
            under.show();
        }
    });
}

$("#outlet-content").on('content-replace', function() {
    runModalUtils();
});
$(window).on('cms-location-change', function() {
    CmsModalUtils.destroyModal();
    CmsModalUtils.removeBlockBodyScroll();
});
